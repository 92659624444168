<template>
  <div class="page-cancel-request-code page-content">
    <KlubbaRichText class="texts">
      <p><b>Cancel subscription</b></p>
      <p>
        Hi{{ getUserKey('first_name') ? ` ${getUserKey('first_name')}` : '' }}, we received a request to change your
        <b>{{ club.name }}{{ selectedClubSubscription?.name ? ` - ${selectedClubSubscription?.name}` : '' }}</b>
        subscription.
      </p>
      <p v-if="firebaseUser">
        If you are sure you would like to cancel your subscription,
        please proceed by clicking a button below.
      </p>
      <p v-else>
        If you are sure you would like to cancel your subscription,
        please log in to your account first.
      </p>
    </KlubbaRichText>

    <div class="button-wrap">
      <klubba-button
        @click="requestCode"
        :buttonText="firebaseUser ? 'Cancel subscription' : 'Log in to Klubba'"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import KlubbaButton from '@/components/default/KlubbaButton.vue';
import KlubbaRichText from '../../components/default/KlubbaRichText.vue';

export default {
  components: {
    KlubbaRichText,
    KlubbaButton,
  },
  computed: {
    ...mapGetters([
      'getUserKey',
      'club',
      'firebaseUser',
      'selectedClubSubscription',
    ]),
  },

  methods: {
    requestCode() {
      if (!this.firebaseUser) {
        this.$router.push(`/${this.club.id}/request-code?proceedTo=cancel-enter-code`);
      } else {
        this.$router.push(`/${this.club.id}/cancel-enter-code`);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.texts
  @apply pt-8 px-8

.container
  @apply px-8

.button-wrap
  @apply p-8 mt-auto
</style>
